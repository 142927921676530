@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik+Dirt&family=Rubik+Distressed&family=Rubik+Glitch&family=Rubik+Wet+Paint&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bruno+Ace&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bruno+Ace&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* Styled fonts for headers */
/* font-family: 'Rubik Dirt', cursive; */
/* font-family: 'Rubik Distressed', cursive; */
/* font-family: 'Rubik Glitch', cursive; */
/* font-family: 'Rubik Wet Paint', cursive; */


:root {
  --black:               #242128;
  --white:               #FFFFFF;
  --primary:             #BDAA7A;
  --secondary:           #E36B58;
  --success:             #6494aa;
  --successLight:        #368f8b; 
  --info:                #4062BB;
  --infoAlt:             #7d80da;
  --disabled:            #c8c8c8;
  --overlay:             rgba(36,33,40,0.8);
  --gray:                rgba(36,33,40,0.6);
  --lightgray:           rgba(36,33,40,0.15);
  --altContainer:        rgba(36,33,40,0.05);
  --overlay50:           rgba(255,255,255,0.25);
  --overlayInverse:      rgba(255,255,255,0.8);
  --overlayInverseAlt:   rgba(255,255,255,0.9);
  --grayInverse:         rgba(255,255,255,0.6);
  --lightgrayInverse:    rgba(255,255,255,0.15);
}

html {
  background: var(--black);
}

body {
  color: var(--white);
  font-family: 'Montserrat', 'Rubik', 'Josefin Sans', sans-serif;
/*  font-optical-sizing: auto;*/
  font-weight: 400;
  font-style: normal;
}

a {
  color: var(--secondary);
  text-decoration: none;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}

.App {
  text-align: center;
}

.thin {
  font-weight: 300;
}

.App-cover.hero .App-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  background: rgba(0,0,0,0.6);
}

.App-cover.hero .buy-card {
    align-self: stretch;
    position: relative;
}

.App-header {
  /* background-color: var(--black); */
  min-height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: var(--white);
  padding: 16px 24px;
  /* border-bottom: 1px solid rgba(0,0,0,0.3); */
  position: fixed;
  z-index: 100;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  background-image: linear-gradient(to bottom, rgba(0,0,0,0.8), transparent);
}

.lockup {
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: flex-start;
  align-items: flex-start;
}

.brand-logo {
  color: var(--primary);
  text-transform: uppercase;
  font-size: 22px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.tagline {
  color: var(--white);
  font-size: 12px;
}

.brand-logo img {
  width: 50px;
  height: 50px;
}

.nav-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(100vw - 48px);
}

.nav-wrapper a {
  text-decoration: none;
  font-weight: 600;
  color: var(--white);
}

button {
  padding: 13px 12px 12px;
  border-radius: 4px;
  background: var(--white);
  border: none;
  font-weight: 800;
  height: 36px;
  font-size: 12px;
  text-transform: uppercase;
  color: var(--black);
  line-height: 12px;
  font-family: 'Montserrat';
}

button:hover {
  opacity: 0.9;
  cursor: pointer;
}

button:disabled {
  background: rgba(0,0,0,0.05) !important;
  cursor: default;
  opacity: 1;
  color: var(--lightgray) !important;
}

.close-button {
  position: relative;
  right: -6px;
}

.close-button:hover {
  cursor: pointer;
}

.nav-buttons {
  display: flex;
  gap: 16px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.navbar-links {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  font-weight: 500;
  height: 40px;
  background-color: var(--gray);
  border-radius: 6px;
  padding: 0 2px;
}

.navbar-nav,
.navbar-nav a {
    display: flex;
}

.App-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 160px 80px 40px 80px;
}

.App-container.viewer {
  padding: 100px 80px 40px 80px;
}

/* .App-cover {
  padding-top: 92px;
} */

.App-cover.hero {
    background: url("./assets/media/images/ase-hero.jpg");
    background-size: cover;
    background-position: center;
    height: 100vh;
    overflow: scroll;
}

h6 {
  font-size: 30px;
  font-family: "Bruno Ace";   
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: var(--primary);
  margin-block-start: 0;
  margin-block-end: 0;
  line-height: 36px;
/*  -webkit-text-stroke: 1px var(--overlay50);*/
}

.art-text {
  background: -webkit-linear-gradient(var(--white) 20%, var(--primary) 40%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

h1 {
  font-size: 100px; 
  font-weight: 700;
  text-transform: uppercase;
  color: var(--primary);
  margin-block-start: 0;
  margin-block-end: 0;
}

.presents {
  text-transform: uppercase;
  font-size: 14px;
}

.App-link {
  color: #61dafb;
}

.navbar-links a {
    padding: 12px;
    border-radius: 4px;
    border: none;
    font-weight: 700;
    max-height: 36px;
    font-size: 12px;
    text-transform: uppercase;
    line-height: 12px;
}

.navbar-links .active {
  /* background: rgba(255,255,255,0.08); */
  background: var(--white);
  box-shadow: 0px 3px 8px rgba(0,0,0,0.5);
  color: var(--secondary);
}

.profile-card {
  background: var(--white);
  color: var(--black);
  padding: 8px 40px 40px 40px;
  border-radius: 4px;
  min-width: 200px;
}

.profile-card h1,
.profile-card h2,
.profile-card h3,
.profile-card h4 {
  margin-block-start: 0;
  margin-block-end: 0;
}

.profile-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:40px;
    margin: 0 -24px 24px -24px;
}

.profile-actions button {
  padding: 0;
  color: var(--secondary);
}

.profile-img,
.profile-initials {
  border-radius: 100%;
  width: 100px;
  height: 100px;
  margin: 0 auto 12px;
}

.profile-initials {
  background: var(--lightgray);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  font-weight: 700;
}

.profile-nav-img,
.profile-nav-initials {
  border-radius: 100%;
  width: 44px;
  height: 44px;
  margin: 0 auto;
  cursor: pointer;
}
.profile-nav-initials {
  background-color: var(--gray);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
}

.profile-head {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-content: center;
  margin-bottom: 36px;
}

.belt-img {
  margin-top: 12px;
  max-width: 100%;
}

.profile-body {
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  align-content: center;
}

.profile-details {
  display: flex;
  flex-direction: row;
  gap: 40px;
  justify-content: space-between;
  align-content: center;
  padding-bottom: 4px;
  border-bottom: 1px solid var(--lightgray);
}

.profile-details a {
  text-transform: none;
  font-size: 16px;
}

.profile-label {
  color: var(--gray);
}

.profile-card .cta {
    margin-top: 24px;
}

#gender-label,
#belt-label,
#matchPref-label,
#style-label {
  background: white;
  padding: 0 6px;
  margin-left: -6px;
}

.capitalize {
  text-transform: capitalize;
}

.gym {
  color: var(--primary);
}

.buy-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: center;
  align-items: flex-start;
  /* max-width: 500px; */
}

.buy-card {
  background: var(--white);
  padding: 4px;
  border-radius: 8px;
  color: var(--black);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 12px;
  width: 450px;
  /* max-width: 450px; */
  /* width: calc((100vw / 3) - 128px); */
  /* max-width: 500px; */
  /* width: calc((100vw / 4) - 118px); */
  /* flex: 1 1 300px; */
}

.buy-card img {
  width: 100%;
  min-height: 250px;
  max-height: 300px;
  object-fit: cover;
  overflow: hidden;
  margin-bottom: 16px;
  border-radius: 6px 6px 0 0;
}

.buy-card .info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;
  width: 100%;
}

.buy-card .event_name {
    font-size: 20px;
    font-weight: 700;
    text-align: left;
    line-height: 28px;
    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    text-transform: uppercase; */
}

.buy-card .edit-event-button {
    position: absolute;
    top: 8px;
    right: 8px;
}

.buy-card .edit-event-button button {
    color: var(--white);
    background: rgba(0,0,0, 0.3);
    backdrop-filter: blur(4px); 
}

.buy-card .edit-event-button button:hover {
    background: rgba(0,0,0, 0.5);
}

.event_name .split-name {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.event_name .base,
.event_name .extra {
  display: inline-block;
  vertical-align: middle;
}

.event_name .extra {
  overflow: hidden;
  white-space: nowrap;
  word-break: keep-all;
  font-weight: 400;
}

.video-item .event_name .split-name {
  justify-content: center;
}

.buy-card .details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 20px;
  padding: 0 20px 24px;
  height: 100%;
}

.buy-card .details .cta {
  width: 100%;
}

.buy-card .event_location {
  display: flex;
  justify-content: flex-start;
  color: var(--secondary);
  gap: 4px;
  align-items: center;
  width: max-content;
  height: 24px;
  padding: 2px 8px 2px 2px;
}

.buy-card .event_location a {
  display: flex;
  align-items: center;
  gap: 4px;
}

.buy-card .price {
  color: var(--success);
  font-weight: 700;
  font-size: 28px;
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 2px 8px;
  background-color: var(--overlayInverse);
  border-radius: 6px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
/*  border: 2px solid var(--grayInverse);*/
    
  display: none;
}

.buy-card .meta {
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  gap: 4px;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: column;
  margin-left: -4px;
}

.buy-card .meta-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
  flex-direction: row;
}

.buy-card .content-container {
  line-height: 24px;
}

.buy-card .date {
  display: flex;
  flex-direction: row;
  gap: 4px;
  color: var(--gray);
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  width: max-content;
  padding: 2px;
}

.buy-card button,
.buy-card .button-link,
.profile-card button.default,
.MuiDialog-root button:not(.MuiIconButton-root):not(.MuiPickersDay-root):not(.MuiPickersYear-yearButton):not(.MuiPickersMonth-monthButton):not(.MuiPickersLayout-actionBar button):not(.MuiPickersToolbar-content button)::not(.MuiTabs-flexContainer button) {
  margin-top: 12px;
  width: 100%;
  height: 40px;
  color: var(--primary);
  background: var(--black);
  outline: none;
  border: 2px solid var(--black);
}

button.cta,
a.cta button {
  width: 100%;
  height: 40px;
  color: var(--primary);
  background: var(--black);
  outline: none;
  border: 2px solid var(--black);
/*  margin: 12px 0;*/
  min-width: 200px;
}

.buy-card .button-link {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}

.buy-card .cal-container {
  border-top: 1px solid var(--lightgray);
  padding-top: 20px;    
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.buy-card .cal-title {
  font-size: 16px;
  color: var(--gray);
}

.buy-card .cal-options {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: center;
  align-items: center;
}

.buy-card .cal-options a {
  border: 2px solid transparent;
  margin: 0;
  padding: 0;
  height: 36px;
}

.buy-card .cal-options a:hover {
  border-color: var(--lightgray);
  border-radius: 100%;
}

.buy-card .fighter-application {
  padding: 12px 0 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  justify-content: center;
}

.buy-card .fighter-application .title,
.buy-card .fighter-application a.apply-link {
  display: inline-block;
  font-size: 12px;
  vertical-align: middle;
  font-weight: 700;
}

.buy-card .fighter-application .apply-link {
  overflow: hidden;
  white-space: nowrap;
  word-break: keep-all;
}

.inline-link {
    text-decoration: underline;
    text-transform: none !important;
    font-size: 1rem;
}

.buy-card .fighter-application .title {
  text-transform: uppercase;
  font-weight: 600;

  padding: 0 4px 0 0;
}

.alert {
    margin-bottom: 24px;
}

.disclaimer {
  font-size: 12px;
  line-height: 16px;
  color: var(--gray);
}

.buy-card.no-stream {
  justify-content: center;
}

.buy-card.no-stream .event_name,
.buy-card.no-stream .content-container {
  text-align: center;
  width: 100%;
}

.buy-card .date,
.buy-card .event_location {
  border-radius: 4px;
  border: 1px solid transparent;
}

.buy-card .date:hover,
.buy-card .event_location:hover {
    border-color: var(--lightgray);
}

.buy-card .chip {
    position:absolute;
    top: 8px;
    left: 8px;
    background-color: var(--secondary);
/*    color: var(--disabled)*/
}

.buy-card.inactive {
    background: var(--disabled);
}

.buy-card.no-events .event_name {
    text-align: center;
}

.buy-card.no-events .icon {
    text-align: center;
    margin-top: 40px;
}

.MuiDialog-root .subtitle {
    font-size: 14px;
    text-transform: uppercase;
    color: var(--gray);
    font-weight: 600;
}

/*
.MuiDialog-root button:not(.MuiIconButton-root):not(.MuiPickersDay-root):not(.MuiPickersYear-yearButton):not(.MuiPickersMonth-monthButton):not(.MuiPickersLayout-actionBar button):not(.MuiPickersToolbar-content button):not(.MuiTabs-flexContainer button) {
  margin: 0 0 12px 0;
  min-width: 200px;
}
*/

.no-stream-icon {
  padding: 24px;
}

.library {
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 80px;
}

.library .video-item img {
  width: 100%;
}

.library .video {
  position: relative;
}

.library .video .play {
  display: none;
}

.library .video .play img {
  width: 100%;
  height: 100%;
}

.library .video .play svg {
  width: 60px;
  height: 60px;
}

.library .video:hover .play {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--gray);
  position: absolute;
  top: 0;
  bottom: 0;
  right:0;
  left:0;
}

.fighter-list {
    position: relative;
    top: 12px;
/*    width: calc(100% - 24px);*/
    width:100%;
    padding: 12px 0 0;
    border-radius: 0px 4px;
/*    background: var(--white);*/
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-top: 1px solid var(--lightgray);
}

.fighter-list:hover {
/*    background: var(--overlayInverse);*/
}

.fighter-list a {
    transition: all .2s;
    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: center;
    color: var(--gray);
    font-size: 12px;
}

.fighter-list a:hover {
    color: var(--secondary);
}

.fighter-list span {
/*
    position: relative;
    top: 3px;
*/
    vertical-align: baseline;
    line-height: 12px;
    max-height: 12px;
}

.table-link {
    color: var(--secondary);
    text-decoration: none;
    text-transform: none;
    font-size: 0.875rem;
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: auto;
}

.past-events.cta {
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    margin: 16px 0;
}

.past-events.cta button {
    background: none;
    text-decoration: underline;
    color: var(--white);
    border-color: transparent;
    font-family: 'Montserrat';
}

.past-events.cta button:hover {
    background: var(--overlay50);
/*    color: var(--primary);*/
    border-color: transparent;
    backdrop-filter: blur(4px);
}



/*
table .align-middle {
    display: flex;
    align-content: center;
    gap: 4px;
}
*/

.align-middle svg {
    position: relative;
    top: 2px;
    padding-right: 4px;
}

table .pick-list {
    position: relative;
    top:-4px;
    padding: 0 4px;
    border-radius: 4px;
}

table .MuiInput-root::before {
    display: none;
}

table .MuiSelect-select {
    font-size: 0.875rem;
}

.divider {
  color: var(--secondary);
}

.video-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.video-item a {
  color: var(--white);
}

/* .library .video-item:not(:last-of-type) {
  width: calc((100vw / 2) - 100px);
} */

.video-item .item-details {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

.video-item .item-details .event-title,
.viewer .item-details .event-title {
  color: var(--primary);
}

.viewer .item-details {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  max-width: 1080px;
  text-align: center;
}

.viewer .event-title {
  display: flex;
  font-size: 22px;
}

.viewer .split-name {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.video-item .date {
  font-size: 1em;
}

.viewer .date {
  font-size: 0.6em;
}

vds-media:not(:defined) > template[shadowroot] ~ * {
  visibility: hidden;
}

vds-video {
  width: 100%;
  max-width: 1080px;
}

.ytp-impression-link,
.ytp-chrome-top.ytp-show-cards-title {
  display: none !important;
}

/*nth-child(2):nth-last-child(3) ---> 4 children present*/
div#top-level-buttons-computed > ytd-toggle-button-renderer:nth-child(2):nth-last-child(3) + ytd-button-renderer{
	display: none !important;
}

/*nth-child(2):nth-last-child(4) ---> 5 children present*/
div#top-level-buttons-computed > ytd-toggle-button-renderer:nth-child(2):nth-last-child(4) + ytd-button-renderer{
	display: none !important;
}

div#top-level-buttons-computed > ytd-toggle-button-renderer:nth-child(2):nth-last-child(4) + ytd-button-renderer + ytd-button-renderer{
	display: none !important;
}

div#flexible-item-buttons > ytd-button-renderer:nth-child(2):nth-last-child(2){

display: none !important;

}

.purchase-options {
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: center;
    align-items: center;
    width: 100%
}

.purchase-options a,
.purchase-options button {
    width: 100%;
}

div#top-level-buttons-computed > ytd-button-renderer{

display: none !important;

}

.MuiDataGrid-columnsPanel {
    font-family: 'Montserrat', 'Rubik';
}

.MuiDataGrid-columnsPanel .MuiFormControlLabel-label,
.MuiFormControl-root,
.MuiFormLabel-root, 
.MuiOutlinedInput-root {
    font-family: 'Montserrat', 'Rubik' !important;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--black) !important;
}

.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: var(--secondary) !important;
}

.MuiFormLabel-colorPrimary.Mui-focused.Mui-error {
        color: var(--secondary) !important;
}

.Mui-disabled .MuiOutlinedInput-notchedOutline {
    border-color: var(--lightgray) !important;
}

.MuiSwitch-colorPrimary.Mui-checked {
    color: var(--success) !important;
}

.Mui-checked + .MuiSwitch-track {
/* background-color: rgba(64, 98, 187, 0.4) !important; */
 background-color: rgba(100, 148, 170, 0.4) !important; 
}

.MuiBadge-badge {
    background-color: var(--success) !important;
}

.MuiButton-root {
    font-family: 'Montserrat', 'Rubik' !important;
    font-weight: 600 !important;
    color: var(--secondary) !important;
}

.MuiButton-root:hover {
     background-color: rgba(227, 107, 89, 0.08) !important; 

}

.MuiFormLabel-colorPrimary.Mui-focused {
    color: var(--gray) !important;
}

.MuiInput-underline:after {
    border-color: var(--black) !important;
}

.MuiMenuItem-root.Mui-selected, 
.MuiDataGrid-row.Mui-selected,
.MuiMenuItem-root:hover, 
.MuiDataGrid-row:hover{
    background-color: rgba(100, 148, 170, 0.08) !important; 
}


.MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-cell:focus-within {
    outline: solid var(--success) 1px !important;
}

.MuiDataGrid-withBorderColor {
    border-color: var(--lightgray) !important;
}

.MuiPickersDay-root.Mui-selected,
.MuiPickersYear-root .MuiPickersYear-yearButton.Mui-selected,
.MuiPickersMonth-root .MuiPickersMonth-monthButton.Mui-selected{
    background-color: var(--success) !important;
    color: var(--white) !important;
}

.MuiPickersYear-root .MuiPickersYear-yearButton,
.MuiPickersMonth-root .MuiPickersMonth-monthButton{
        line-height: 0;
}

.MuiPickersDay-root.Mui-selected:hover,
.MuiPickersYear-root .MuiPickersYear-yearButton.Mui-selected:hover,
.MuiPickersMonth-root .MuiPickersMonth-monthButton.Mui-selected:hover {
       background-color: var(--success) !imporant;
}

.hidden .Mui-disabled .MuiOutlinedInput-notchedOutline  {
    border-color: transparent !important;
}

.hero .admin-actions {
    position: fixed;
    right: 24px;
    bottom: 20px;
    display: flex;
    flex-direction: column-reverse;
    gap: 16px;
}

.MuiFab-root.MuiFab-primary {
    background-color: var(--primary);
/*    border: 1px solid var(--black);*/
    color: var(--black);
}

.MuiFab-root.MuiFab-primary:hover {
    background-color: var(--primary);
    opacity: 0.8;
}

.MuiFab-root.MuiFab-secondary {
    background-color: var(--white);
/*    border: 1px solid var(--black);*/
    color: var(--black);
}

.MuiFab-root.MuiFab-secondary:hover {
    background-color: var(--white);
    opacity: 0.8;
}

/*
.MuiPickersArrowSwitcher-button {
    padding: 7px 5px;
}
*/

.MuiMultiSectionDigitalClockSection-item.Mui-selected {
    background-color: var(--success) !important;
}

.MuiTabs-flexContainer button.Mui-selected {
    color: var(--secondary);
}

.MuiTabs-indicator {
    background-color: var(--secondary) !important;
}

.MuiDateTimePickerToolbar-timeContainer  button,
.MuiDateTimePickerToolbar-dateContainer button{
 height: auto;   
}

.MuiPickersToolbar-content {
    align-items: flex-end !important;
}

.MuiClock-pin,
.MuiClockPointer-root,
.MuiClockPointer-thumb {
    background-color: var(--success) !important;
}

.MuiClockPointer-thumb {
    border-color: var(--success) !important;
}

.MuiDialogActions-root .cta {
    margin: 12px 0;
}

.MuiTypography-root,
.MuiToolbar-root,
.MuiTablePagination-selectLabel,
.MuiInputBase-root,
.MuiTablePagination-displayedRows,
.MuiDialogTitle-root,
.MuiDataGrid-columnHeaderTitle,
.MuiDataGrid-cellContent,
.MuiDataGrid-cell--withRenderer,
.MuiDataGrid-selectedRowCount,
.MuiMenuItem-root {
  font-family: 'Montserrat' !important;   
}

.MuiDialogTitle-root,
.MuiTypography-h6,
.MuiDataGrid-columnHeaderTitle {
    font-weight: 700 !important;
}

.MuiDataGrid-cellContent,
.MuiDataGrid-cell--withRenderer,
.MuiTypography-body  {
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
}

[data-field="commentary"] .MuiDataGrid-cellContent {
    text-transform: none ;
}

.purchase-options .alt,
.purchase-options .cta .alt {
    background: none;
    color: var(--black);
    border-color: var(--black);
}

.purchase-options .alt:hover {
  background: var(--black);
  color: var(--primary);
}

img.form-image-presenter {
  width: 100%;
  min-height: 250px;
  max-height: 300px;
  object-fit: cover;
}

.event-subtitle {
    font-weight: 400;
}

.address-autocomplete input {
    font-family: 'Montserrat', 'Rubik';
    height: 56px;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.23);
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    padding: 0 14px;
    display: block;
    transform-origin: top left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,-webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    z-index: 1;
    pointer-events: auto;
    border-radius: 4px;
    outline: none;
    color: rgba(0, 0, 0, 0.6);
}

.address-autocomplete input:hover {
    border-color: var(--black);
}

.address-autocomplete input:active,
.address-autocomplete input:focus {
    border-width: 2px;
    border-color: var(--black);
}

.pac-container {
    z-index: 1000000 !important;
}

@media screen and (max-width: 586px) {
  .App-cover.hero {
    min-height: calc(100vh - 92px);
  }

  .App-container,
  .App-container.viewer {
    /* padding: 40px 24px; */
    padding: 100px 24px 24px 24px;
  }

  .App-cover.hero .App-container {
      min-height: calc(100vh - 140px);
  }

  .App-container.library {
    gap: 40px;
  }

  .item-details {
    flex-direction: column;
    gap: 8px;
  }

  .buy-card {
    width: 100%;
  }
  h2,
  .viewer .event-title,
  .video-item .date {
/*    font-size: 1.25em;*/
  }
}

@media screen and (max-width: 450px) {
  h2,
  .viewer .event-title,
  .video-item .date {
/*    font-size: 1em;*/
  }

  .buy-card img {
    min-height: 200px;
  }
}


@media screen and (min-width: 501px) {
  .App-cover.hero .App-container {
      min-height: calc(100vh - 200px);
  }
}

@media screen and (min-width: 800px) {
  .App-container.library {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 40px;
  }

  .profile-card {
    max-width: 100%;
  }
}

@media screen and (min-width: 1023px) {
  .library .video-item {
    width: calc((100vw / 2) - 100px);
  }
}

@media screen and (min-width: 1600px) {
  .library .video-item {
    width: calc((100vw / 3) - 80px);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
